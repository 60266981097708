const dashboard = {
  discover: {
    askurl_title: "Phishing, Smishing, Qshing detection and analysis service",
    askurl_expl:
      "AskURL uses similarity analysis technology and generative AI technology to detect and analyze cyber threats such as Phishing, Smishing, and Qshing that come in through Web Mail, SMS, Messenger, etc. in real time.",
  },
  search: {
    domain_placeholder: "Please Enter Domain or URL.",
    sms_placeholder: "Please enter SMS text.",
    qr_placeholder: "Please attach the QR code (image).",
    resource_placeholder: "Please enter a Resource Hash value.",
  },
  worldmap: {
    detection_by_country: "Ranking of {cls} detection by country",
    detection_by_type: "Detection rate by type",
  },
  latest: {
    latest: "Latest analysis status",
    date: "Date",
    status: "Status",
    resource: "Resource Count",
    link: "Link Count",
    tags: "Tags",
    country: "Country",
  },
  blog: {
    title: "Nurilab Security Blog",
  },
  boannews: {
    title: "Security News",
  },
  news: {
    title: "AskURL Update News",
  },
};

export default dashboard;
