const dashboard = {
  discover: {
    askurl_title: "Phishing, Smishing, Qshing 탐지 및 분석 서비스",
    askurl_expl:
      "AskURL은 Web Mail, SMS, Messenger 등을 통해 유입되는 Phishing, Smishing, Qshing 등의 사이버 위협을 유사도 분석 기술과 생성형 AI 기술을 활용하여 실시간 탐지하고 분석합니다.",
  },
  search: {
    domain_placeholder: "Domain, URL을 입력하세요.",
    sms_placeholder: "SMS 문자를 입력하세요.",
    qr_placeholder: "QR코드(이미지)를 첨부하세요.",
    resource_placeholder: "Resource Hash값을 입력하세요.",
  },
  worldmap: {
    detection_by_country: "국가별 {cls} 탐지 순위",
    detection_by_type: "유형별 탐지 비율",
  },
  latest: {
    latest: "최근 분석 현황",
    date: "날짜",
    status: "상태",
    resource: "리소스 수",
    link: "링크 수",
    tags: "태그",
    country: "국가",
  },
  blog: {
    title: "누리랩 보안 블로그",
  },
  boannews: {
    title: "보안 뉴스",
  },
  news: {
    title: "AskURL 업데이트 소식",
  },
};

export default dashboard;
